import React from "react";
import { useParallaxController } from 'react-scroll-parallax';

import Layout from "src/components/Layout";
import CategoryLayouts from "src/components/CategoryLayouts";
import { TopArticlesCategory } from "src/components"

const Category = (props) => {
  const parallaxController = useParallaxController();
  const updateParallaxState = () => parallaxController.update();

  const { pageContext } = props;
  const { categoryComponents } = pageContext;
  const layouts = categoryComponents.components || [];
  return (
    <div className="category-page-wrapper">
      <Layout>
        {layouts.map((layout, index) => (
          <CategoryLayouts key={index} layoutData={layout} />
        ))}
        <TopArticlesCategory category={pageContext} updateParallaxState={updateParallaxState} />
      </Layout>
    </div>
  ) 
}

export default Category;

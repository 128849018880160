import React, { useEffect } from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import { useLocation } from '@gatsbyjs/reach-router';
import { useTranslation } from 'react-i18next';
// import useWindow from 'src/hooks/useWindow';
import setLanguage from 'src/utils/setLanguage';
import useComponentWillMount from 'src/hooks/useComponentWillMount';

import CategoryWrapper from './CategoryWrapper';
import DefaultHead from 'src/components/head-defaults';
import Seo from "src/components/seo";
import replaceAmpersand from 'src/utils/replaceAmpersand';

const Category = ({ pageContext }) => {
  // const _window = useWindow();
  const location = useLocation();
  const {i18n} = useTranslation();
  useComponentWillMount(() => {setLanguage(location.pathname, i18n)});
  useEffect(() => {
    localStorage.setItem('articleFallbackUrl', '');
  }, []);
  return (
    <ParallaxProvider>
      <CategoryWrapper {...{ pageContext }} />
    </ParallaxProvider>
  )
}
export default Category;


export const Head = ({ pageContext }) => {
  const { name, description } = pageContext;

  const posts = pageContext?.posts?.nodes
  let imageurl
  if( posts && posts.length ) {
    imageurl = posts[0]?.featuredImage?.sourceUrl
  }

  return (
    <>
      <DefaultHead />

      <Seo
        title={replaceAmpersand(name) || "Untitled"}
        description={ description }
        imageUrl={ imageurl }
        articleUrl={pageContext.url}
        articleLang={pageContext.language.slug}
        pageContext={pageContext}
        type="category"
      />
    </>
  )
   
}
